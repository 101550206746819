import { FC, ReactNode } from 'react';
import {
  Box,
  Skeleton,
  Stack,
  Typography,
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  type TextWithInlineLink as TextWithInlineLinkType,
  isEmpty,
} from '@shared/lib';
import {
  HtmlBlock,
  TextWithInlineLink
} from '@shared/ui';
import {
  StepLoader
} from './step-loader.component';

type StepPlayInThreeSteps = {
  iconSrc: Url;
  title: string;
  description: string;
};

export type PlayInThreeStepsProps = {
  actionSlot?: ReactNode;
  title: TextWithInlineLinkType;
  steps: Array<StepPlayInThreeSteps>;
  loading?: boolean;
};

export const PlayInThreeSteps: FC<PlayInThreeStepsProps> = ({
  title,
  steps,
  actionSlot,
  loading
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(490));
  return (
    <Stack
      direction='column'
      sx={({ palette, breakpoints }) => ({
        minHeight: 386,
        backgroundColor: palette.common[475],
        borderRadius: 8,
        gap: 5,
        paddingBlock: 5,
        paddingInline: 8,
        position: 'relative',
        [breakpoints.down(912)]: {
          gap: 2.5,
          paddingBlock: 3,
          paddingInline: 2,
        }
      })}>
      {loading ? (
        <Skeleton
          width={120}
          height={42}
          variant='inline'
          loading={loading}
          sx={{ marginInline: 'auto' }}
        />
      ) : !isEmpty(title) && (
        <TextWithInlineLink
          data={title}
          typographyProps={{
            textAlign: 'center',
            fontSize: 30,
            ...(isMobile && {
              display: 'flex',
              justifyContent: 'center',
              gap: '8px',
              textAlign: 'start',
              fontSize: 24,
            }),
          }}
        />
      )}
      <Stack
        sx={({ breakpoints }) => ({
          flexDirection: 'row',
          alignItems:
            isMobile
              ? 'flex-start'
              : 'center',
          gap: 3,
          justifyContent: 'space-between',
          [breakpoints.down(912)]: {
            flexDirection: 'column',
            gap: 2.5,
          }
        })}>
        {loading ? (
          Array(3).fill(null).map((_, i) => <StepLoader key={i} />)
        ) : (
          steps.map(({ iconSrc, title, description }, index) => (
            <Box
              maxWidth={326}
              key={title}>
              <Stack
                direction='row'
                justifyContent={
                  isMobile
                    ? 'flex-start'
                    : 'center'
                }
                alignItems='center'
                gap={1.5}>
                <Box
                  component='img'
                  loading='lazy'
                  src={iconSrc}
                  sx={{
                    height: 60,
                    width: 60
                  }}
                />
                <Typography
                  sx={({ palette }) => ({
                    fontSize: 20,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '28px',
                    color: palette.common.white,
                  })}>
                  {title}
                </Typography>
              </Stack>
              <HtmlBlock html={description} />
            </Box>
          ))
        )}
      </Stack>
      {actionSlot && (
        <Stack
          direction='row'
          justifyContent='center'>
          {actionSlot}
        </Stack>
      )}
    </Stack>
  );
};
