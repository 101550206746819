import { FC, MouseEvent, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Modal,
  ModalDialog,
  ModalDialogProps,
  Stack,
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import { 
  baseOmegaApi, 
  COIN_PACKAGES_TAG 
} from '@shared/api';
import {
  CloseIcon,
  IconButton,
} from '@shared/ui';
import {
  isDefined,
  isEmpty,
} from '@shared/lib';
import {
  type Package,
  PackageCard,
  openCheckoutModal,
  selectCoinPackages,
  useCoinPackagesSelector,
  useGetPackagesFromOmegaQuery,
} from '@entities/coin-packages';
import {
  sortCoinPackages
} from '../lib';
import {
  FreePlayDisclaimer
} from './free-play-disclaimer.component';

export type CoinPackagesModalProps = Pick<ModalDialogProps, 'sx'> & {
  open: boolean;
  titleSlot?: ReactNode;
  onClose?(): void;
};

export const CoinPackagesModal: FC<CoinPackagesModalProps> = ({
  open,
  onClose,
  titleSlot,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(490));
  const dispatch = useDispatch();

  const coinPackages = useCoinPackagesSelector(selectCoinPackages);
  const { isFetching } = useGetPackagesFromOmegaQuery();

  const handleClose = (
    event: MouseEvent<HTMLButtonElement>,
    reason: 'backdropClick' | 'escapeKeyDown' | 'closeClick'
  ): void => {
    event?.stopPropagation();
    event?.preventDefault();
    if (reason === 'closeClick') onClose?.();
  };

  useEffect(() => {
    dispatch(baseOmegaApi.util.invalidateTags([COIN_PACKAGES_TAG]));
    // eslint-disable-next-line
  }, [])

  const handleBuyPackage = (selectedPackage: Partial<Package>): void => {
    onClose?.();
    dispatch(openCheckoutModal(selectedPackage));
  };

  return !isFetching && (
    <Modal
      open={open}
      onClose={handleClose}>
      <ModalDialog
        layout={(
          isMobile
            ? 'fullscreen'
            : 'center'
        )}
        sx={({ breakpoints, palette }) => ({
          width: '100%',
          maxWidth: 488,
          maxHeight: 878,
          backgroundColor: palette.common[925],
          [breakpoints.down(491)]: {
            maxHeight: '100%',
            borderRadius: 0,
            boxShadow: 'none',
          },
        })}
        {...props}>
        <Stack
          flexDirection='column'
          sx={({ breakpoints }) => ({
            width: '100%',
            height: '100%',
            padding: 3,
            [breakpoints.down(490)]: {
              paddingInline: 2,
              paddingTop: 2,
              paddingBottom: 4,
            },
            [breakpoints.down(390)]: {
              paddingInline: 1,
              paddingBottom: 2,
            },
          })}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent={
              isDefined(titleSlot)
                ? 'space-between'
                : 'flex-end'
            }
            sx={{
              width: '100%',
              marginBottom: 3,
            }}>
            {titleSlot}
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Box
            sx={({ breakpoints }) => ({
              display: 'grid',
              gap: '0.75rem',
              gridTemplateColumns: '1fr 1fr',
              gridAutoRows: 'minmax(min-content, max-content)',
              height: `calc(100vh - 330px)`,
              overflow: 'auto',
              width: '100%',
              marginBottom: 1.5,
              [breakpoints.down(490)]: {
                height: '100%'
              },
              [breakpoints.down(360)]: {
                gridTemplateColumns: '1fr',
              }
            })}>
            {isEmpty(coinPackages) ? (
              <Box
                alignItems='center'
                justifyContent='center'>
                No offers available!
              </Box>
            ) : (
              sortCoinPackages(coinPackages)
                .map((packageCardProps) => (
                  <PackageCard
                    key={packageCardProps.packageId}
                    onBuyPackage={handleBuyPackage}
                    {...packageCardProps}
                  />
                )))}
          </Box>
          <FreePlayDisclaimer />
        </Stack>
      </ModalDialog>
    </Modal>
  );
};
