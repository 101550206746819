import {
  FC,
  Fragment,
  ReactNode,
} from 'react';
import {
  Divider,
  Modal,
  ModalDialog,
  Stack,
  Typography,
  useTheme as useJoyTheme
} from '@mui/joy';
import {
  useTheme as useMaterialTheme,
  useMediaQuery
} from '@mui/material';
import {
  CheckCircleOutlineRounded as CheckCircleOutlineRoundedIcon
} from '@mui/icons-material';
import {
  CloseIcon,
  IconButton
} from '@shared/ui';
import {
  formatCurrency
} from '@shared/lib';
import {
  usePaymentsSelector,
  selectWorldPayCheckoutResult,
  selectOpenWorldPayCheckoutSuccessPopup
} from '../model';

type StyledTypographyProps = {
  children: ReactNode;
  sx?: object;
};

const StyledTypography: FC<StyledTypographyProps> = ({ children, sx }) => (
  <Typography
    sx={({ palette }) => ({
      fontSize: 18,
      color: palette.common[100],
      ...sx
    })}>
    {children}
  </Typography>
);

export type WorldPayCheckoutSuccessPopupProps = {
  receiptFooterSlot?: ReactNode;
  onClose?(): void;
};

export const WorldPayCheckoutSuccessPopup: FC<WorldPayCheckoutSuccessPopupProps> = ({
  receiptFooterSlot,
  onClose
}) => {
  const joyTheme = useJoyTheme();
  const materialTheme = useMaterialTheme();
  const isMobile = useMediaQuery(materialTheme.breakpoints.down(490));
  const checkoutResult = usePaymentsSelector(selectWorldPayCheckoutResult);
  const open = usePaymentsSelector(selectOpenWorldPayCheckoutSuccessPopup);
  return (
    <Modal open={open} disablePortal>
      <ModalDialog
        layout={(
          isMobile
            ? 'fullscreen'
            : 'center'
        )}
        sx={({ breakpoints, palette }) => ({
          width: '100%',
          height: '100%',
          maxWidth: 488,
          maxHeight: 578,
          backgroundColor: palette.common[925],
          [breakpoints.down(491)]: {
            maxHeight: '100%',
            borderRadius: 0,
            boxShadow: 'none',
          },
        })}>
        <Stack
          direction='column'
          sx={({ breakpoints }) => ({
            width: '100%',
            height: '100%',
            padding: 3,
            [breakpoints.down(490)]: {
              paddingInline: 2,
              paddingTop: 2,
              paddingBottom: 4,
            },
            [breakpoints.down(390)]: {
              paddingInline: 1,
              paddingBottom: 2,
            },
          })}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              width: '100%',
              marginBottom: 3,
            }}>
            <Typography
              level='h3'
              fontSize='1.25rem'>
              Checkout
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            direction='column'
            justifyContent='flex-start'
            width='100%'
            height='100%'
            rowGap={7}>
            <Stack
              direction='column'
              alignItems='center'
              justifyContent='space-between'
              rowGap={3}
              sx={({ breakpoints }) => ({
                rowGap: 3,
                [breakpoints.down(490)]: {
                  paddingTop: 4,
                  rowGap: 4,
                }
              })}>
              <CheckCircleOutlineRoundedIcon
                sx={{
                  fontSize: 112,
                  color: joyTheme.palette.common[825]
                }}
              />
              <Typography
                sx={({ palette }) => ({
                  fontSize: 22,
                  fontWeight: 'bold',
                  color: palette.common.white
                })}>
                {formatCurrency(checkoutResult?.amount ?? 0)}
              </Typography>
            </Stack>
            <Stack
              direction='column'
              alignItems='center'
              justifyContent='space-between'
              sx={{
                rowGap: 1,
                paddingInline: 1
              }}>
              {checkoutResult?.cardNumber && (
                <Fragment>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    width='100%'>
                    <StyledTypography>
                      Card number
                    </StyledTypography>
                    <StyledTypography>
                      {checkoutResult?.cardNumber}
                    </StyledTypography>
                  </Stack>
                  <Divider
                    orientation='horizontal'
                    sx={({ palette }) => ({
                      backgroundColor: palette.common[900]
                    })}
                  />
                </Fragment>
              )}
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                width='100%'>
                <StyledTypography>
                  Purchase amount
                </StyledTypography>
                <StyledTypography>
                  {formatCurrency(checkoutResult?.amount ?? 0)}
                </StyledTypography>
              </Stack>
              <Divider
                orientation='horizontal'
                sx={({ palette }) => ({
                  backgroundColor: palette.common[900]
                })}
              />
              {checkoutResult?.referenceId && (
                <Fragment>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    width='100%'>
                    <StyledTypography>
                      Reference ID
                    </StyledTypography>
                    <StyledTypography>
                      {checkoutResult?.referenceId}
                    </StyledTypography>
                  </Stack>
                  <Divider
                    orientation='horizontal'
                    sx={({ palette }) => ({
                      backgroundColor: palette.common[900]
                    })}
                  />
                </Fragment>
              )}
              {checkoutResult?.paymentId && (
                <Fragment>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    width='100%'>
                    <StyledTypography>
                      Payment ID
                    </StyledTypography>
                    <StyledTypography>
                      {checkoutResult?.paymentId}
                    </StyledTypography>
                  </Stack>
                  <Divider
                    orientation='horizontal'
                    sx={({ palette }) => ({
                      backgroundColor: palette.common[900]
                    })}
                  />
                </Fragment>
              )}
              {receiptFooterSlot ? receiptFooterSlot : (
                <StyledTypography sx={{ marginTop: 2 }}>
                  Receipt of your purchase
                </StyledTypography>
              )}
            </Stack>
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};