import {
  ComponentType,
  Fragment,
  useEffect,
  useMemo
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  Typography
} from '@mui/joy';
import {
  useToaster
} from '@shared/ui';
import {
  useQueryParamModalVisibility,
  useModalQueryParam
} from '@shared/lib';
import {
  featureToggles
} from '@shared/config';
import {
  UserRegistrationStatusEnum
} from '@shared/types';
import {
  useSessionSelector,
  selectIsAuthenticated,
  UserKycStatusEnum,
  selectRegistrationStatus,
  selectKycStatus,
} from '@entities/session';
import {
  WorldPayCheckoutFailedPopup,
  WorldPayCheckoutSuccessPopup,
  closeWorldPayCheckoutFailedPopup,
  openWorldPayCheckoutFailedPopup,
  closeWorldPayCheckoutSuccessPopup,
  openWorldPayCheckoutSuccessPopup,
  selectActionPurpose,
  usePaymentsSelector
} from '@entities/payments';
import {
  selectOpenCheckoutModal,
  useCoinPackagesSelector
} from '@entities/coin-packages';
import {
  useSendDataToGtm
} from '@features/collect-analytics';
import {
  PaymentIQCashierCheckoutModal,
  CoinPackagesModal,
  PleaseConfirmYourIdentityModal,
  WorldPayCheckoutModal,
} from '@features/buy-coins';

export const withBuyModal = <T extends object>
  (WrappedComponent: ComponentType<T>) =>
  (props: T) => {
    const dispatch = useDispatch();
    const { error } = useToaster();
    const { closeModal, openModal } = useModalQueryParam();

    const isAuthenticated = useSessionSelector(selectIsAuthenticated);
    const kycStatus = useSessionSelector(selectKycStatus);
    const registrationStatus = useSessionSelector(selectRegistrationStatus);
    const openPayCheckoutModal = useCoinPackagesSelector(selectOpenCheckoutModal);
    const isBuyActionPurpose = usePaymentsSelector(selectActionPurpose('buy'));
    const { sendDataToGtm } = useSendDataToGtm();
    const openBuyModal = useQueryParamModalVisibility('buy');

    const allowToBuy = useMemo(() =>
      registrationStatus === UserRegistrationStatusEnum.Player &&
      kycStatus === UserKycStatusEnum.Pass,
      [registrationStatus, kycStatus]
    );

    const openCoinPackagesModal = useMemo(
      () => openBuyModal && isAuthenticated && allowToBuy,
      [openBuyModal, isAuthenticated, allowToBuy]
    );

    const openPleaseConfirmYourIdentityModal = useMemo(
      () => openBuyModal && isAuthenticated && !allowToBuy,
      [openBuyModal, isAuthenticated, allowToBuy]
    );

    useEffect(() => {
      if (!isAuthenticated && openBuyModal)
        error({
          message: 'You are not authorized! Please log in to your account to gain access',
          autoHideDuration: 1000
        });
      // eslint-disable-next-line
    }, []);

    const handleClose = (): void => {
      closeModal('buy');
    };

    const handleDepositStart = (): void => {
      sendDataToGtm({
        dataLayer: {
          event: 'purchase_start',
        }
      })
    };

    const handleDepositSuccess = (
      depositResult?: {
        amount: string;
        cardNumber?: string;
        referenceId?: string;
      }
    ): void => {
      if (depositResult) {
        dispatch(openWorldPayCheckoutSuccessPopup({
          actionPurpose: 'buy',
          result: depositResult
        }));
      }
      sendDataToGtm({
        dataLayer: {
          event: 'purchase_success',
          depositAmount: depositResult?.amount,
          depositCurrency: 'USD',
          depositcount: '1',
          paymentMethod: 'Trustly'
        }
      });
    };

    const handleDepositFail = (
      depositResult?: {
        amount: string;
        cardNumber?: string;
        referenceId?: string;
      }
    ): void => {
      if (depositResult) {
        dispatch(openWorldPayCheckoutFailedPopup({
          actionPurpose: 'buy',
          result: depositResult
        }));
      }
      sendDataToGtm({
        dataLayer: {
          event: 'purchase_fail',
          paymentMethod: 'Trustly'
        }
      });
    };

    const handleCloseWorldPayCheckoutSuccessPopup = (): void => {
      dispatch(closeWorldPayCheckoutSuccessPopup());
    };

    const handleCloseWorldPayCheckoutFailedPopup = (): void => {
      dispatch(closeWorldPayCheckoutFailedPopup());
    };

    const handleWorldPayCheckoutTryAgain = (): void => {
      dispatch(closeWorldPayCheckoutFailedPopup());
      openModal('buy');
    };

    return (
      <Fragment>
        <WrappedComponent {...props} />
        {openCoinPackagesModal ? (
          <CoinPackagesModal
            open={openCoinPackagesModal}
            titleSlot={(
              <Typography
                level='h2'
                fontSize='1.25rem'>
                Coin packages
              </Typography>
            )}
            onClose={handleClose}
          />
        ) : null}
        <PleaseConfirmYourIdentityModal
          open={openPleaseConfirmYourIdentityModal ?? false}
          titleSlot={(
            <Typography
              level='h2'
              fontSize='1.25rem'>
              Coin packages
            </Typography>
          )}
          onClose={handleClose}
        />

        {featureToggles.paymentIQCashier && openPayCheckoutModal && (
          <PaymentIQCashierCheckoutModal
            onDepositFail={handleDepositFail}
            onDepositStart={handleDepositStart}
            onDepositSuccess={handleDepositSuccess}
          />
        )}

        {!featureToggles.paymentIQCashier && (
          <Fragment>
            {openPayCheckoutModal && (
              <WorldPayCheckoutModal
                onDepositFail={handleDepositFail}
                onDepositStart={handleDepositStart}
                onDepositSuccess={handleDepositSuccess}
              />
            )}
            {isBuyActionPurpose && (
              <Fragment>
                <WorldPayCheckoutSuccessPopup
                  onClose={handleCloseWorldPayCheckoutSuccessPopup}
                />
                <WorldPayCheckoutFailedPopup
                  onClose={handleCloseWorldPayCheckoutFailedPopup}
                  onTryAgain={handleWorldPayCheckoutTryAgain}
                />
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  };
