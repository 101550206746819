import {
  FC,
  useEffect,
  useState,
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  Modal,
  ModalDialog,
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  useToaster
} from '@shared/ui';
import {
  OmegaApiResponseStatusEnum
} from '@shared/api';
import {
  formatCurrency
} from '@shared/lib';
import {
  CheckoutForm,
  PaymentMethodEnum,
  WorldPayCheckoutResult
} from '@entities/payments';
import {
  selectFullProfile,
  useLazyBalanceQuery,
  useSessionSelector
} from '@entities/session';
import {
  type PrepareWithdrawalResponse,
  type ProcessWithdrawalResponse,
  useLazyProcessWithdrawalQuery,
  useLazyCancelWithdrawalQuery,
  useLazyPrepareWithdrawalQuery,
} from '../api';
import {
  closeConfirmCoinRedemptionPopup,
  selectCoinRedeem,
  selectIsOpenConfirmCoinRedemptionPopup,
  useRedeemCoinsSelector
} from '../model';

export type WorldPayConfirmCoinRedemptionPopupProps = {
  onRedeemStart: (amount: string | number) => void,
  onRedeemSuccess: (redeemResult?: WorldPayCheckoutResult) => void,
  onRedeemFail: (redeemResult?: WorldPayCheckoutResult) => void
};

export const WorldPayConfirmCoinRedemptionPopup: FC<WorldPayConfirmCoinRedemptionPopupProps> = ({
  onRedeemStart,
  onRedeemFail,
  onRedeemSuccess,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(490));

  const toast = useToaster();
  const dispatch = useDispatch();

  const open = useRedeemCoinsSelector(selectIsOpenConfirmCoinRedemptionPopup);
  const coinRedeem = useRedeemCoinsSelector(selectCoinRedeem);
  const userProfile = useSessionSelector(selectFullProfile);

  const [getBalanceQuery] = useLazyBalanceQuery();
  const [prepareWithdrawalQuery] = useLazyPrepareWithdrawalQuery();
  const [processWithdrawalQuery] = useLazyProcessWithdrawalQuery();
  const [cancelWithdrawal] = useLazyCancelWithdrawalQuery();

  const [processWithdrawal, setProcessWithdrawal] = useState<Maybe<Partial<ProcessWithdrawalResponse>>>(null);

  useEffect(() => {
    const processWithdrawal = async (amount: number): Promise<void> => {
      onRedeemStart(amount);

      const paymentMethod = PaymentMethodEnum.WorldPayCNPCard;

      const prepareWithdrawalResponse = await prepareWithdrawalQuery({ paymentMethod });
      const { status: prepareWithdrawalStatus, conversionId } = prepareWithdrawalResponse.data as PrepareWithdrawalResponse;

      if (![OmegaApiResponseStatusEnum.Success, OmegaApiResponseStatusEnum.ContinueToPaymentSite].includes(prepareWithdrawalStatus)) {
        toast.error({ message: prepareWithdrawalStatus, autoHideDuration: 5000 });
        await handleClose();
        return;
      }

      const processWithdrawalResponse = await processWithdrawalQuery({ amount, conversionId, paymentMethod });
      const { status: processWithdrawalStatue, ...processWithdrawal } = processWithdrawalResponse.data as ProcessWithdrawalResponse;

      if (![OmegaApiResponseStatusEnum.Success, OmegaApiResponseStatusEnum.ContinueToPaymentSite].includes(processWithdrawalStatue)) {
        toast.error({ message: processWithdrawalStatue, autoHideDuration: 5000 });
        await handleClose();
        return;
      } else {
        setProcessWithdrawal(processWithdrawal);
      }
    }

    if (coinRedeem)
      processWithdrawal(coinRedeem);

    return () => {
      dispatch(closeConfirmCoinRedemptionPopup());
    };
    // eslint-disable-next-line
  }, []);

  const handleClose = async (): Promise<void> => {
    await cancelWithdrawal({ paymentId: processWithdrawal?.paymentId });
    await getBalanceQuery();
    dispatch(closeConfirmCoinRedemptionPopup());
  };

  const handleRedeemSuccess = async (
    redeemResult?: {
      amount: string;
      cardNumber: string;
      referenceId: string;
    }
  ): Promise<void> => {
    dispatch(closeConfirmCoinRedemptionPopup());
    await getBalanceQuery();
    onRedeemSuccess?.(redeemResult);
  };

  const handleRedeemFail = (depositResult?: {
    amount: string;
    cardNumber?: string;
    referenceId?: string;
  }
  ): void => {
    dispatch(closeConfirmCoinRedemptionPopup());
    onRedeemFail?.(depositResult);
  };

  return (
    <Modal open={open ?? false} disablePortal>
      <ModalDialog
        layout={(
          isMobile
            ? 'fullscreen'
            : 'center'
        )}
        sx={({ breakpoints, palette }) => ({
          width: '100%',
          height: '100%',
          maxWidth: 488,
          maxHeight: 678,
          backgroundColor: palette.common[925],
          [breakpoints.down(491)]: {
            maxHeight: '100%',
            borderRadius: 0,
            boxShadow: 'none',
          },
        })}>
        {processWithdrawal && (
          <CheckoutForm
            amount={coinRedeem}
            submitButtonLabelSlot={`Redeem ${formatCurrency(coinRedeem)}`}
            checkoutInfo={{
              merchantTxId: processWithdrawal?.merchantTxId ?? 0,
              paymentId: processWithdrawal?.paymentId ?? 0,
              payPageId: processWithdrawal?.payPageId ?? '',
              reportGroup: processWithdrawal?.reportGroupId ?? ''
            }}
            billingInfo={{
              billingAddress1: userProfile?.address,
              billingCity: userProfile?.city,
              billingCountry: userProfile?.country,
              billingEmail: userProfile?.email,
              billingFirstName: userProfile?.firstName,
              billingLastName: userProfile?.lastName,
              billingPhone: userProfile?.phone,
              billingState: userProfile?.province,
              billingPostalCode: userProfile?.postalCode
            }}
            onCheckoutFail={handleRedeemFail}
            onCheckoutSuccess={handleRedeemSuccess}
            onClose={handleClose}
          />
        )}
      </ModalDialog>
    </Modal>
  );
};